.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}
/* src/App.css */
.thank-you {
  text-align: center;
  margin-top: 50px;
}

.thank-you h1 {
  font-size: 2.5rem;
  color: #4CAF50;
}

.thank-you p {
  font-size: 1.2rem;
  margin-top: 20px;
}





@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /* animation: App-logo-spin infinite 20s linear;
    */
  } 
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
