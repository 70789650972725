@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;700&display=swap');


:root {
  --primary: #6750a4;
  --on-primary: #ffffff;
  --secondary: #cbc2db;
  --on-secondary: #ffffff;
  --accent: #efb8c8;
  --on-accent: #ffffff;
  --error: #ba1a1a;
  --on-error: #4f378a;
  --bg: rgb(22 39 56 / 90%);
  --on-bg: #1c1b1e;
}

body {
  margin: 0px;
  padding: 0px;
  position: relative;
  top: 0;
  font-family: 'Poppins', sans-serif;
}

* {
  box-sizing: border-box;
}

header {
  width: 100%;
  position: fixed;
  background: var(--bg);
  display: grid;
  grid-template-columns: 1fr auto minmax(40vw, 600px) 1fr 1fr;
  z-index: 999;
  height: 9vh;
}

header img {
  grid-column: 2/3;

  padding: .5em 0;

  display: grid;
  margin: auto;
  height: 9vh;
}

.nav-toggle {
  display: none;
}

nav {
  grid-column: 3/4;
  width: 100%;
  padding-right: 2em;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-content: center;
}

header.active {
  box-shadow: 7px 3px 14px 0px rgb(121 114 221 / 41%);
  transition: all ease 0.2s;
}

.cart-container {
  --bg:#dadce0;
  background: var(--bg);
  margin: 9vh 0.25em 0 .25em;
  padding: 0 0.75em 0.75em .75em;
  position: fixed;
  right: 0px;
  transition: all .2s;
  z-index: 99;
  box-shadow: -4px 4px 7px 0px rgb(1 1 1 / 81%);
}

.cart-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 94%;

}

.cart-image img {
  --size: 121px;
  width: var(--size);
  padding: .5em;
}

/* .item-details
{

} */
.cart-title h5 {
  margin: 0;
}

/* .cart-price{} */
.cart-count {
  display: flex;
  flex-direction: row;
  gap: .375em;
  background: #cdbda7;
  width: -moz-fit-content;

  width: fit-content;
  border-radius: 12px;
  justify-content: space-around;
}

.cart-count button:first-child,
.cart-count button:nth-child(3) {
  --size: 25px;
  border-radius: 50%;
  border: none;
  padding: .1em;
  height: var(--size);
  width: var(--size);
  cursor: pointer;
}

.cart-count p {
  margin: 0;
  padding: 0 .375em;
}

.cart-item-total {
  align-self: end;
}

.item-total-price {
  width: 64px;
  align-self: end;
}

.item-total-price p {
  text-align: end;
  padding: 0 .5em;
  margin: 0;
  margin-bottom: 9px;
}

.total-price p {
  text-align: end;
}

.total-price h5 {
  margin: 0;
  float: left;
  padding-left: 1em;
}

.checkout-btn {
  display: grid;
  place-items: center;
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
}

nav ul li {
  margin-left: 2em;
}

nav ul a {
  text-decoration: none;
  color: var(--accent);
  text-transform: uppercase;
  padding: 0.5em 1px;
  text-align: center;
}

nav ul a:hover {
  background: var(--on-bg);
  color: var(--on-accent);
}

.top-menu {
  display: flex;
  flex-direction: row;
  gap: .75em;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 2em;
}

.login button {
  background-color: white;
  color: black;
}

.login button:hover {
  background-color: rgb(173, 138, 138);
  color: black;
}

.cart>button {
  border: none;
  background: transparent;
  cursor: pointer;
}

.cart>button>span {
  position: absolute;

  top: 7px;
  text-align: center;
  border-radius: 7px;
  width: 18px;
  height: 18px;
  background-color: #ff6161;
  border: 1px solid #fff;
  font-weight: 400;
  color: #f0f0f0;
  line-height: 16px;
  font-size: 12px;
}

.cart>button>svg {
  --size: 24px;
  display: inline-block;
  width: var(--size);
  height: var(--size);
  color: white;
}

.intro {
  padding-top: 9vh;
  background-image: linear-gradient(135deg, #cfcba4, #b20a2c);
}

.intro h4 {
  text-align: center;
}

.intro .wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap-reverse;
}

.text-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em 2.5em;
  width: 400px;
}

.text-box h2 {
  padding: 1.5em;
  text-transform: capitalize;
  margin: 0;
}

.text-box p {
  padding: .5em 1.5em;
  text-transform: lowercase;
  margin: 0;

}

.text-box a {
  all: unset;
  font-size: 0.7em;
  padding: 0.7em 1.6em;
  cursor: pointer;
}

.btn-accent {
  padding: .7em 1.6em;
  outline: none;
  background: #462fc5f2;
  color: #ffffff;
  border: none;
  cursor: pointer;
  margin: 6px;
  border-radius: 2px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  transition: .2s ease-in-out;
  text-transform: uppercase;
}

.btn-accent:hover {
  background: #6f5cd9;
  -webkit-box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
  box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
}

.usr {
  --size: 3em;
  border-radius: 50%;
  padding: 1em;
  width: var(--size);
  height: var(--size);
}


.usr-menu {
  background: #fff;
  position: absolute;
  transform: scale(1, 1);
  transform-origin: top;
  transition: all .2s;
  box-shadow: -4px 4px 7px 0px rgb(1 1 1 / 81%);
}

.usr-menu ul {
  padding: 1em 1em;
  list-style: none;
  padding: 0 1.25em;
  margin: 0;
}

.usr-menu ul li {
  padding: .75em;
  width: 100%;
  border-bottom: 1px solid #000;
}

.usr-menu ul>li a {

  text-decoration: none;
  text-align: center;
  color: #474544;
}

.usr-menu ul>li:last-child {
  border: none;
}

.usr-menu ul>li button {
  all: unset;
  cursor: pointer;

}

.delivery-img {
  width: 350px;

}

.delivery-img img:hover {

  translate: 0px -4px;

}


.delivery-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 9px;
  transition: .2s all;

}



.about {
  width: 100%;
  /* margin-top: -19px; */
  padding-top: 9vh;
  /* background: #ccb697; */
  /* padding: 1em 1.5em; */
  /* background: linear-gradient(to bottom, #cbb093, #b41432); */
  padding-bottom: 2em;
}


.about h3 {
  padding: 1em 0;
  ;
  text-align: center;
}

.about-text {
  width: 70%;
  /* padding: 1em 3em; */
  margin: auto;
  text-align: justify;

  font-size: 1.3em;
}

.a-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 2em;
  width: 100%;

  padding: 2em 1.5em;
  transition: 0.3s ease-in-out;

}

.a-box {
  background: var(--on-accent);
  border-radius: 9px;
  width: 300px;
  height: 400px;
  box-shadow: 6px 6px 8px rgb(86 81 81 / 34%);
  transition: all .2s;
  cursor: pointer;
}

.a-box:hover {
  translate: 0px 4px;
  box-shadow: 6px 6px 6px rgb(157 143 143 / 10%);


}

.a-b-img {
  width: 100%;
  height: 55%;

}

.a-b-img img {
  padding: 16px;
  object-fit: contain;
  height: 100%;
  width: 100%;

}

.a-b-text {
  text-align: center;
  margin: 0;
}

.a-b-text h3 {
  color: var(--accent);
  margin: 0;

}

.a-b-text p {
  color: var(--bg);
  padding: 0 0.5em;

}

.btn-container a {
  display: block;
  width: 100%;
  text-decoration: none;
}

.btn-container button {
  display: grid;
  margin: auto;
}

.products-container {
  width: 100%;
  padding: 0 1em 2em 1em;
  padding-top: 9vh;
  /* background: var(--bg); */
  background: linear-gradient(135deg, #9f9696, #b7b7cb, #327483)
}

.products-container h3 {
  text-align: center;
}

.products {
  width: 100%;
  display: flex;
  gap: 2em;
  flex-wrap: wrap;
  justify-content: space-evenly;

  padding: 2em;
}

.product {
  --bg: #ebe4e0;
  width: 300px;
  background: var(--bg);
  padding: 1em 0;
  border-radius: 4px;
  box-shadow: 5px 5px 6px 0px rgb(141 128 128 / 81%);
  transition: all .2s;
  width: 256px;
}

.product:hover {
  /* translate: 0px -2px; */
  box-shadow: 3px 3px 6px 0px rgb(141 128 128 / 81%);

}

.product-img {
  width: 100%;
  display: grid;
  place-items: center;
  overflow: hidden;
  padding: .5em 0;
}

.product-img img {

  --size: 169px;
  border-radius: 50%;

  object-fit: cover;
  width: var(--size);
  height: var(--size);
  transition: all .3s;

}

.product-img img:hover {

  transform: scale(1.05);
}

.product-card {
  padding: 0 1rem;
}

.product-title {
  margin-top: 0;

}

.product-title h6 {
  font-family: 'Josefin', sans-serif;
  --text-color: #413950;
  color: var(--text-color);
  font-size: 16px;
  margin: .4em 0;
  padding: 0.5em 0;
  text-transform: uppercase;
}

.product-details>p {
  margin: 0.4em 0;

}

.product-details>p>span:first-child {

  font-size: 16px;
}

.product-offer {

  margin: .4em 0;
}

.product-offer>span {
  background: #a3a53e;
  font-size: 12px;
  padding: .2em;
  color: white;
}

.product-offer small {
  padding-left: .3em;
}

.product-btn {
  display: flex;
  justify-content: space-between;
}

.product-btn button {
  font-weight: 500;
  outline: none;
  border: none;
  padding: .5em .75em;
  margin: .5em .25em;
  border-radius: 3px;
  cursor: pointer;
  text-transform: uppercase;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.product-btn button:hover {
  background: #6f5cd9;
  box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
}

.product-btn button:first-child {
  background: rgb(58 58 84);
  color: #e4cece;
}

.product-btn button:nth-child(2) {
  background: #474544;
  color: #e4cece;
}

.explore-btn a {
  width: 100%;
  display: block;
  text-decoration: none;
}

.explore-btn button {
  margin: auto;
  display: grid;
}



.contact-us h4 {
  text-align: center;
  text-transform: uppercase;
}

.contact-form {
  padding: 25px;
  width: 66%;
  margin: auto;
}

.form-group {
  display: flex;
  gap: 3em;
}

.contact-form input[type='text'],
[type='email'],
[type='tel'],
select,
textarea {
  background: none;
  border: none;
  border-bottom: solid 2px #474544;
  color: #474544;
  font-size: 1.000em;
  font-weight: 400;
  letter-spacing: 0.5px;
  margin: 0em 0 1.875em 0;
  padding: 0 0 0.875em 0;
  width: 45%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.contact-form textarea {
  width: 100%;
}

.contact-form input[type='text']:focus,
[type='email']:focus,
textarea:focus {
  outline: none;
  padding: 0 0 0.875em 0;
}

#form_button {
  background: none;
  border: solid 2px #474544;
  color: #474544;
  cursor: pointer;
  display: inline-block;
  font-family: 'Helvetica', Arial, sans-serif;
  font-size: 0.875em;
  font-weight: bold;
  outline: none;
  padding: 20px 35px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

#form_button:hover {
  background: #474544;
  color: #F2F3EB;
}

.footer {
  padding: 1em 0;
  width: 100%;
  background: black;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

}

.footer .app-link {
  padding-left: .5em;
  width: 20%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.footer img {
  --size: 121px;
  width: var(--size);

}

.company {
  color: white;
}

.privacy {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.privacy a {

  text-decoration: none;
  color: white;
  padding: 9px;
  margin: 4px;
}


@media screen and (max-width:400px) {}

@media screen and (max-width:800px) {

  header img {
    height: 8vh;
    width: auto;
    grid-column: 3/3;


  }

  /* .login 
  {
    
    
  } */
  .top-menu {
    
    
    position: absolute;
    right: 0;
    top: 0;
    
    gap: 0.25em;
    width: 27%;
    margin: 0;
   
    
  }

  nav {
    position: absolute;
    background: var(--bg);
    text-align: left;
    top: 100%;
    left: 0;
    transform: scale(1, 0);
    transform-origin: top;
    transition: transform 250ms ease-in-out;
    grid-column: unset;
    justify-content: center;
  }

  nav ul {
    all: unset;
    display: flex;
    margin: 0;
    padding: 0;
    flex-direction: column;
    list-style: none;
  }

  nav li {
    all: unset;
    margin-bottom: 1em;
    margin-left: 1em;

  }

  nav a {
    display: block;
    text-decoration: none;
    width: 100%;
    padding: 0.5em 1px;

  }

  .nav-toggle:checked~nav {
    transform: scale(1, 1);
    /* display: block; */
  }

  .nav-toggle-label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 2em;
    cursor: pointer;
  }

  .nav-toggle-label span,
  .nav-toggle-label span::before,
  .nav-toggle-label span::after {
    display: block;
    position: relative;
    background: var(--on-accent);
    height: 2px;
    width: 2em;
    border-radius: 2px;

  }

  .nav-toggle-label span::before,
  .nav-toggle-label span::after {
    content: '';
    position: absolute;
  }

  .nav-toggle-label span::before {
    bottom: 7px;
  }

  .nav-toggle-label span::after {
    bottom: -7px;
  }

  .intro {
    padding-top: 9vh;
    background-image: linear-gradient(to bottom, #cfcba4, #b20a2c);

  }

  .wrapper .text-box {
    width: 300px;
    padding: 0 1em;
    padding-bottom: 2em;
    text-align: center;
  }

  .text-box :is(h2, p) {
    color: #d7cacae0;
    padding: 1em 0;
  }

  .wrapper .delivery-img {
    width: 300px;
  }

  /* .about {
    background: linear-gradient(to top, #cbb093, #b41432);
  } */

  .products-container {
    background: linear-gradient(to bottom, #9f9696, #b7b7cb, #327483);
  }

  .contact-form {
    padding: 14px;
    width: 81%;
    margin: auto;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  .contact-form input[type='text'],
  [type='email'],
  [type='tel'],
  select,
  textarea {

    width: 91%;

  }

  .submit {
    width: 96%;
  }

  #form_button {
    display: block;
    margin: auto;
    padding: 14px 28px;
  }

  .footer {
    flex-direction: column;
  }

  .footer .app-link {
    width: 100%;
  }

  .company {
    text-align: center;
  }

  .privacy {
    width: 100%;
    padding: .5em 0;
  }
}

@media only screen and (min-width:800px) and (max-width:1024px) {
  .wrapper :is(.text-box, .delivery-img) {
    width: 45%;
  }
}

@media only screen and (min-width:1024px) {
  .wrapper :is(.text-box, .delivery-img) {
    width: 40%;
    padding: 1em 2em;

  }

  .text-box h2 {
    font-size: 1.5rem;
  }

  .text-box p {
    font-size: 1.1em;
  }

  .text-box :is(.btn-accent) {
    margin: 9px;
    font-size: 1.2rem;
  }
}